import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const Templogin = () => {
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (!email) {
        toast.error("Please enter email address ");
        return;
      }
      if (!password) {
        toast.error("Please enter Password");
        return;
      }
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/student/login`, {
          email: email,
          password: password,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.msg === "success") {
            localStorage.setItem("token", res.data.token);

            window.location.href = "/student";
            // toast.warning("Student Login is under maintainance atm.");

            // window.location.href =
            //   "/register/success/not/reg/first/fmasnmfnjsbfj";
          } else if (res.data.msg === "notreg") {
            toast.error("Email Not Registered");
          } else if (res.data.msg === "wrongpass") {
            toast.error("Incorrect Password");
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Error Logging in");
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <form onSubmit={handleLogin}>
        <div style={{ marginTop: "2.5rem" }}>
          <p className="formtext "> Email</p>

          <input
            value={email}
            type="email"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            className="forminput col-md-12 col-12"
            style={{
              marginTop: "12px",
            }}
          />
          <div style={{ marginTop: "1.25rem" }}>
            <p className="formtext ">Password</p>
            <input
              value={password}
              placeholder=""
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className="forminput col-md-12 col-12 "
              style={{ marginTop: "12px" }}
            />
          </div>
        </div>
        <div style={{ marginTop: "2rem", width: "100%" }}>
          <button
            className="continuebutton"
            style={{ width: "100%" }}
            type="submit"
            disabled={loading}
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
};
export default Templogin;
